import moment from "moment"
import environment from "../environment"

const isTranslatePage = () => {
  let value = false
  let url = window.location.href
  if (url.includes('translation')) value = true
  return value
}

const generatekeysArr = (arr, key = 'typeofresult') => {
  let keys = {}
  if (!arr) return { keys, arr: [] }
  arr.map(itm => {
    if (keys[itm[key]]) {
      keys[itm[key]].push(itm)
    } else {
      keys[itm[key]] = [itm]
    }
  })
  return {
    keys, arr: Object.keys(keys).map(itm => {
      return { key: itm, value: keys[itm] }
    })
  }
}

const TimeSlotsFormat = (data) => {
  try {
    if (!data) return null;

    const convertTo12HourFormat = (time) => {
      const [hours, minutes, seconds] = time.split(':').map(Number);
      const period = hours >= 12 ? 'PM' : 'AM';
      const hour12 = hours % 12 || 12; // Convert to 12-hour format, replacing "0" with "12"
      return `${hour12}:${String(minutes).padStart(2, '0')} ${period}`;
    };

    if (data.toLowerCase().includes('am') || data.toLowerCase().includes('pm')) {
      return data; // Already formatted correctly
    } else {
      const formattedStart = convertTo12HourFormat(data);
      return `${formattedStart}`;
    }
  } catch {
    return data
  }
};
const userImg = (img) => {
  let value = '/assets/img/placeholder.png'
  if (img?.includes('https')) {
    return img
  }
  if (img) value = environment.imageApi + img
  if (img && img.includes('http')) value = img
  return value
}

const noImg = (img, modal = 'img') => {
  let value = '/assets/img/placeholder.png'
  if (img?.includes('https')) {
    return img
  }
  if (img) value = environment.imageApi + img
  if (img?.includes('http')) value = img
  return value
}

const getPrams = (p) => {
  const params = new URLSearchParams(window.location.search)
  return params.get(p)
}


const isNumber = (e) => {
  let key = e.target;
  let maxlength = key.maxLength ? key.maxLength : 1;

  key.value = key.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
  let max = Number(key.max ? key.max : key.value);
  if (Number(key.value) > max) key.value = max;

  let min = key.min;
  if (min && Number(key.value) < Number(min)) key.value = min;
  if (key.value.length > maxlength && maxlength > 0) key.value = key.value.slice(0, maxlength);
  return key.value
}

const isRatio = (e) => {
  let key = e.target;
  let maxlength = key.maxLength ? key.maxLength : 1;

  // let max = Number(key.max ? key.max : key.value);
  // if (Number(key.value) > max) key.value = max;

  // let min = key.min;
  // if (min && Number(key.value)<Number(min)) key.value = min;

  if (key.value.length > maxlength) key.value = key.value.slice(0, maxlength);
  key.value = key.value.replace(/[^0-9.>]/g, '').replace(/(\..*?)\..*/g, '$1');

  return key.value
}

const find = (arr, value, key = 'key') => {
  let ext = arr?.find(itm => itm[key] == value)
  return ext
}


/* ###################### Form Methods #########################  */

// get Single field error
const getError = (key, fvalue, formValidation) => {
  let ext = find(formValidation, key)
  let res = matchError(ext, fvalue)
  return res
}

const emailRequiredFor = (role) => {
  let value = false
  if (role == 'Clinic Admin' || role == 'Counsellor' || role == 'Owner' || role == 'admin') value = true
  return value
}


const validateUsername = (val) => {
  return /^(?=[a-zA-Z0-9._-]{8,20}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/.test(val);
}

const dialMatch = (val) => {
  let value = false
  value = val.match(/^(?=.*[0-9])(?=.*[+])[0-9+]{2,5}$/)
  return value
}
const emailvalidation = (val) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val)) {
    return true
  }
  return false
}
// match errors for fields
const matchError = (ext, fValue) => {

  let invalid = false
  let kValue = fValue[ext.key]
  let value = { minLength: false, maxLength: false, confirmMatch: false, required: false }
  if (ext.required) {
    if (!kValue || (!kValue.length && typeof kValue != 'object')) {
      invalid = true
    }
  }
  if (ext.minLength && kValue) {
    if (kValue.length < ext.minLength) value.minLength = true
  }
  if (ext.maxLength && kValue) {
    if (kValue.length > ext.maxLength) value.maxLength = true
  }
  if (ext.dialCode && kValue) {
    if (dialMatch(kValue)) {
      kValue.indexOf("+");
      if (kValue.indexOf("+") != 0) {
        value.dialCode = true
      }

    } else {
      value.dialCode = true
    }
  }

  if (ext.username && kValue) {
    if (!validateUsername(kValue)) value.username = true
  }

  if (ext.confirmMatch && kValue) {
    if (fValue[ext.confirmMatch[0]] != fValue[ext.confirmMatch[1]]) value.confirmMatch = true
  }

  let vArr = Object.keys(value)
  vArr.map(itm => {
    if (value[itm]) invalid = true
  })

  let res = { invalid: invalid, err: value }
  return res
}

// get form error (All Fields)
const getFormError = (formValidation, fvalue) => {
  let invalid = false
  formValidation.map(ext => {
    if (matchError(ext, fvalue).invalid) {

      invalid = true
    }
  })

  return invalid
}

/* ###################### Form Methods end #########################  */

const route = (route) => {
  localStorage.setItem('route', route)
  let el = document.getElementById('routerDiv')
  setTimeout(() => {
    if (el) el.click()
  }, 100)
}


const EmailValidator = (email) => {
  if (email?.includes('.')) {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let splitedEmail = email.split('.');
    if (splitedEmail.length) {
      let lastEmail = splitedEmail[splitedEmail.length - 1];
      if (lastEmail.length > 5) {
        return false
      }
    }
    return re.test(String(email)?.toLowerCase());
  } else {
    return false
  }

}


const URLvalidator = (url) => {
  let regex = /^(https?:\/\/)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/\S*)?$/;;
  if (regex.test(url)) {
    //  if (url.includes('https://') || url.includes('http://')){
    //     return true
    //  }else{
    return true
    //  }
  } else {
    return false
  }

}


const URLTextFinder = (text) => {

  let urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    // return '<a href="' + url + '">' + url + '</a>';
    function removeHtmlTags(html) {
      return html.replace(/<[^>]*>/g, '');
    }
    return `<a href='${removeHtmlTags(url)}' target='__blank'>${url} </a>`
  });
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
}

function colorfulAvatar(name, height = 8) {
  if (typeof name !== 'string' || name.length === 0) {
    return ''; // return empty string if input is not valid
  }

  // Array of Tailwind CSS text colors
  const textColors = ['text-red-500', 'text-blue-500', 'text-green-500', 'text-yellow-500', 'text-purple-500'];

  // Array of Tailwind CSS background colors
  const bgColors = ['bg-red-200', 'bg-blue-200', 'bg-green-200', 'bg-yellow-200', 'bg-purple-200'];

  // Convert the first character of the name to uppercase
  const firstLetter = name.charAt(0).toUpperCase();

  // Calculate ASCII value of the first character
  const charCode = firstLetter.charCodeAt(0);

  // Use modulus operation to get indices for colors based on ASCII value
  const randomTextColorIndex = charCode % textColors.length;
  const randomBgColorIndex = charCode % bgColors.length;

  // Generate HTML with Tailwind CSS classes for styling
  const coloredAvatar = <div class={`flex items-center shrink-0 justify-center h-${height} w-${height} rounded-full bg-[#E9D5FF]`}>
    <div class="text-lg font-medium text-[#A855F7]">{firstLetter}</div>
  </div>;

  return coloredAvatar;
}



function MonthNameGetter(dt) {
  // Define an array containing names of months
  const mlist = ['', "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  // Return the name of the month corresponding to the month index of the provided date
  return mlist[dt];
};

const ConverterHtmlCode = (html = '') => {

  // Create a DOM element to parse the HTML string
  let parser = new DOMParser();
  let doc = parser.parseFromString(html, 'text/html');

  // Select all anchor tags and set their target attribute
  doc.querySelectorAll('a').forEach(anchor => {
    anchor.setAttribute('target', '_blank');

  });
  // Serialize the modified HTML back to a string
  let serializer = new XMLSerializer();
  let updatedHtmlString = serializer.serializeToString(doc);

  return updatedHtmlString
}
function capitalizeFirstLetter(name) {
  try {
    return name?.charAt(0)?.toUpperCase() + name?.slice(1)?.toLowerCase();
  } catch {
    return ''
  }
}
function replaceCommasWithBreaks(str) {
  // Use a regular expression to replace all commas with <br/>
  return str.replace(/,/g, ', <br/>');
}

function validatePassword(password) {
  // Define regular expressions for validation
  const lengthRegex = /^.{8,16}$/; // Between 8 and 16 characters
  const lowercaseRegex = /[a-z]/;  // At least one lowercase letter
  const uppercaseRegex = /[A-Z]/;  // At least one uppercase letter
  const numberRegex = /[0-9]/;     // At least one number
  const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;"'<>,.?/~`|\\]/; // At least one special character
  // Check length
  if (!lengthRegex.test(password)) {
    return ({ success: false, message: "Password must be between 8 and 16 characters long." })
  }

  // Check for lowercase letter
  if (!lowercaseRegex.test(password)) {
    return ({ success: false, message: "Password must include at least one lowercase letter." })
  }

  // Check for uppercase letter
  if (!uppercaseRegex.test(password)) {
    return ({ success: false, message: "Password must include at least one uppercase letter." })
  }

  // Check for number
  if (!numberRegex.test(password)) {
    return ({ success: false, message: "Password must include at least one number." })
  }

  // Check for special character
  if (!specialCharRegex.test(password)) {
    return ({ success: false, message: "Password must include at least one special character." })
  }

  // If all checks pass
  return ({ success: true, message: "Password is valid." })
}


const MomentFormat = (date, format = '') => {
  try {
    return moment(date)?.format(format)
  }
  catch {
    return ''
  }
}


const WaiverNameChanger = (userData = {}, string = '') => {
  let text = 'Waiver';
  if (userData?.companyAddress?.toLowerCase()?.includes(`zealand`)) {
    text = 'Risk Disclosure Form'
  }
  if (string) {
    return `${text}s`
  } else {
    return text
  }
}

const DynamicPricingDataConvertor = async (data, type = '') => {
  if (type == 'procharter') {
    return data?.map((item, index) => {
      return { ...item, startTimeLocal: item?.DateTimeFrom, endTimeLocal: item?.DateTimeTo, priceOptions: item?.priceOptions?.map((item) => ({ ...item, price: item?.Price, label: 'adult' })), endTime: item?.DateTimeTo, startTime: item?.DateTimeFrom, seatsAvailable: TotalDataCalculator(item?.priceOptions, 'Available'), seats: TotalDataCalculator(item?.priceOptions, 'Quantity') }
    })
  }
  if (type == 'fareharbor') {
    const updatedData = await data?.map((item, index) => {
      const StartTime = item?.start_at?.split("+")[0]
      const EndTime = item?.end_at?.split("+")[0]
      return { ...item, startTimeLocal: StartTime, endTimeLocal: EndTime, priceOptions: item?.customer_type_rates?.map((item) => ({ ...item, price: item?.customer_prototype?.total, label: item?.customer_prototype?.display_name })), endTime: item?.end_at, startTime: item?.start_at, seatsAvailable: item?.capacity, seats: item?.capacity }
      // TotalDataCalculator(item?.customer_type_rates, 'capacity')
    })
    return updatedData
  }
  else {
    return data?.data?.map((item, index) => {
      return { ...item, startTimeLocal: `${item?.date}`, endTimeLocal: `${item?.end_date}`, priceOptions: [{ price: item?.tour_price, label: 'adult' }], endTime: item?.end_date, startTime: item?.date, seatsAvailable: item?.available_seats, seats: item?.allocated_seats }
    })
  }
}
const CurrentServer = () => {
  if (window.location.href?.includes('app.dazhboards')) {
    return false
  } else {
    return true
  }
}

const PlanFeatureReturner = (res) => {
  let ActivePlanData = { ...res.data, planFeatures: res?.data?.planFeatures?.filter((itemp) => itemp?.included) };
  let data = ActivePlanData?.planAddons?.map((item) => item?.slug || item?.name) || [];
  ActivePlanData?.planFeatures?.map((item) => {
    let ourData = (item?.slug || item?.featureId?.slug) || (item?.name || item?.featureId?.name);
    data?.push(ourData)
  });
  return data;
}
function getPreviousYears(count = 20) {
  const currentYear = new Date().getFullYear();
  const previousYears = [];

  for (let i = 1; i <= count; i++) {
    previousYears.push(currentYear - i);
  }

  return previousYears;
}

const EveryObjectKeyCheck = (arr) => {
  return arr.some(obj =>
    Object.values(obj).some(value => value === null || value === undefined || value === "")
  );
}

// Function to set a cookie with a 30-minute expiration
function setCookie(name, value) {
  const now = new Date();
  now.setTime(now.getTime() + 30 * 60 * 1000); // 30 minutes in milliseconds
  const expires = `expires=${now.toUTCString()}`;
  document.cookie = `${name}=${value}; ${expires}; path=/`;
}

// Function to get a cookie value by name
function getCookie(name) {
  const nameEQ = `${name}=`;
  const cookiesArray = document.cookie.split(';');
  for (let i = 0; i < cookiesArray.length; i++) {
    let cookie = cookiesArray[i].trim(); // Remove leading/trailing whitespaces
    if (cookie.indexOf(nameEQ) === 0) {
      return cookie.substring(nameEQ.length);
    }
  }
  return null; // Return null if the cookie is not found
}


const textPatternFormatting = (text) => {
  const pattern = /^(###\s*(.*$))|\*\*\[(.+?)\]\((.+?)\)\*\*|\*\*(.*?)\*\*|_(.*?)_|`(.*?)`|\[(.+?)\]\((.+?)\)|(https?:\/\/[^\s]+)|(\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b)|(\+\d{10,15})|\[Book Here\]\n\((.+?)\)/gm;

  if (text?.toLowerCase() === 'want to talk live agent') {
    return 'Would you like to connect with a live agent for further assistance?';
  }

  return text.replace(pattern, (match, header, headerText, boldLinkText, boldLinkUrl, boldText, italicText, codeText, linkText, linkUrl, directLink, email, phoneNumber, bookHereUrl) => {
    if (headerText) {
      // Handles "### Header"
      return `<h3>${headerText.trim()}</h3>`;
    } else if (boldLinkText && boldLinkUrl) {
      // Handles "**[text](url)**"
      return `<b><a href="${boldLinkUrl}" target="_blank" style="color: blue; text-decoration: underline;">${boldLinkText}</a></b>`;
    } else if (boldText) {
      // Handles "**bold text**"
      return `<b>${boldText}</b>`;
    } else if (italicText) {
      // Handles "_italic text_"
      return `<i>${italicText}</i>`;
    } else if (codeText) {
      // Handles "`inline code`"
      return `<code>${codeText}</code>`;
    } else if (linkText && linkUrl) {
      // Handles "[link text](url)"
      return `<a href="${linkUrl}" target="_blank" style="color: blue; text-decoration: underline;">${linkText}</a>`;
    } else if (email) {
      // Handles email links
      return `<a href="mailto:${email}" target="_blank" style="color: blue; text-decoration: underline;">${email}</a>`;
    } else if (phoneNumber) {
      // Handles phone numbers
      return `<a href="tel:${phoneNumber}" target="_blank" style="color: blue; text-decoration: underline;">${phoneNumber}</a>`;
    } else if (bookHereUrl) {
      // Handles "[Book Here]\n(link_url)"
      return `<a href="${bookHereUrl}" target="_blank" style="color: blue; text-decoration: underline;">Book Here</a>`;
    }
    return match; // Fallback for unmatched cases
  }).replace(/\n/g, '<br>'); // Converts line breaks to <br>
};
const CalendarTextReturner = (text) => {
  if (text?.includes('<div class="price price_n">"$0 "  <br></div>') || !text) {
    return ''
  } else {
    return text
  }
}
const methodModel = { EveryObjectKeyCheck, TimeSlotsFormat, CalendarTextReturner, textPatternFormatting, setCookie, getCookie, CurrentServer, getPreviousYears, PlanFeatureReturner, DynamicPricingDataConvertor, WaiverNameChanger, MomentFormat, validatePassword, replaceCommasWithBreaks, ConverterHtmlCode, userImg, colorfulAvatar, route, URLTextFinder, URLvalidator, isNumber, EmailValidator, isRatio, find, getError, getFormError, getPrams, emailRequiredFor, emailvalidation, noImg, isTranslatePage, generatekeysArr, MonthNameGetter, capitalizeFirstLetter }
export default methodModel   
