import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { search_success } from "../../../actions/search";
import { login_success, logout } from "../../../actions/user";
import Html from "./Html";
import environment from "../../../environment";
import { toast } from "react-toastify";
import socket from "../../../models/socket.model";
import { FcIdea } from "react-icons/fc";
import { Subscription } from "../../../actions/Subscription";


const Header = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [isOpen1, setIsOpen1] = useState(false);
  const toggle1 = () => setIsOpen1(!isOpen1);
  const history = useNavigate();

  const searchState = useSelector((state) => state.search);

  useEffect(() => {
    try {
      console.clear()
    } catch {

    }
  }, [])
  const Logout = async () => {
    swal({
      title: "Are you sure?",
      text: "You want to Logout!",
      icon: "warning",
      buttons: {
        cancel: {
          text: "No",
          value: null,
          visible: true,
          className: "bg-red-400 text-white",
          closeModal: true,
        },
        confirm: {
          text: "Yes",
          value: true,
          visible: true,
          className: "btn btn-[#481196]",
          closeModal: true,
        }
      },
      className: 'smallmodal'
    }).then((result) => {
      if (result) {
        if (user?._id) {
          socket.emit(`user-offline`, { user_id: user?._id })
        }
        dispatch(logout());
        dispatch(Subscription({}))
        localStorage.removeItem("persist:admin-app");
        localStorage.removeItem("token");

        let keys = Object.keys(localStorage)
        keys = keys.filter(itm => !itm.includes(environment.api)).map(itm => ({ key: itm, value: localStorage[itm] }))
        localStorage.clear()
        sessionStorage.clear()
        clearAllCookies()
        history("/login");
      }
    });
  };


  function clearAllCookies() {
    let cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      let eqPos = cookie.indexOf("=");
      let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  useEffect(() => {
    let UserDetail = { ...user, sidebar: isOpen };
    dispatch(login_success(UserDetail));
  }, [isOpen]);
  const [UserData, setUserData] = useState({})
  const user = useSelector((state) => state.user);
  useEffect(() => {
    setUserData({ ...user })
  }, [user])

  useEffect(() => {
    window.scrollTo({ top: 0 });
    if (searchState.data) {
      dispatch(search_success(""));
    }
  }, []);

  useEffect(() => {
    setSearch(searchState.data);
  }, [searchState]);

  const [search, setSearch] = useState("");

  const searchHandle = (e) => {
    e.preventDefault();
    dispatch(search_success(search));
  };

  const searchChange = (e) => {
    setSearch(e);
    if (!e) {
      dispatch(search_success(""));
    }
  };

  const clear = () => {
    setSearch("");
    dispatch(search_success(""));
  };
  const [unreadCount, setunreadCount] = useState(0)
  const SocketInterval = useRef();
  useEffect(() => {

    socket.emit(`join-socket`, { user_id: user?.id || user?._id })
    SocketInterval.current = setInterval(() => {
      socket.emit(`unread-chats`, { user_id: user?.id || user?._id })
    }, 200000);
    return () => {
      clearInterval(SocketInterval.current)
    }

  }, [])

  useEffect(() => {
    socket.on(`unread-chats-count`, (data) => {
      if (data?.data?.user_id == user?._id) {
        setunreadCount(data?.total_live_unread_chats)
      }
    })
  }, [])

  useEffect(() => {
    if (user?._id) {
      socket.emit(`user-online`, { user_id: user?._id })
    }
    return () => {
      if (user?._id) {
        socket.emit(`user-offline`, { user_id: user?._id })
      }
    }
  }, [])



  return (
    <Html
      isOpen={isOpen}
      toggle={toggle}
      searchHandle={searchHandle}
      search={search}
      user={user}
      searchChange={searchChange}
      unreadCount={unreadCount}
      setIsOpen={setIsOpen}
      isOpen1={isOpen1}
      clear={clear}
      Logout={Logout}
    />
  );
};

export default Header;
