



import { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import { FiEdit3 } from "react-icons/fi";
import ApiClient from "../../methods/api/apiClient";
import { useSelector } from "react-redux";
import ProductTable from "./ProductTable";
import loader from "../../methods/loader";
import SelectDropdown from "../../components/common/SelectDropdown";
import MultiSelectDropdown from "../../components/common/MultiSelectDropdown";
import environment from "../../environment";

function BookingSetting({ formState, handleChange, toggleProductSelection, ButtonSaved, setButtonSaved, handleSubmit, GetPageSettings, AllProducts }) {
    const user = useSelector((state) => state?.user)

    const urlAllow = (url) => {
        if (user?.subRole?.id == environment.SubRolePartner) {
            return true
        }
        let permissions = user?.subRole && user?.subRole?.permissions && user?.subRole?.permissions[0];
        let arr = url?.split(",");
        let value = false;
        arr.map((itm) => {
            if (permissions?.[itm]) value = true;
        });
        if (user?.subRole?.id || user?.subRole?._id) {
            return value;
        } else {
            return true;
        }
    };

    return (
        <>

            <div className="max-w-[1020px]">
                <div className='p-2 flex items-center justify-between'>
                    <div className="flex flex-col gap-1">
                        <h4 className="text-2xl font-semibold text-[#111827]">
                            Order Page Settings
                        </h4>
                        <p className="text-sm font-normal text-[#75757A]">
                            Here you can change the Order Page Settings
                        </p>
                    </div>



                </div>
                <div className="">
                    <div className="flex-3">
                        <form className="shadow-box border !border-grey  rounded-large container p-5 bg-white" onSubmit={handleSubmit} >

                            {/* Use Booking Page */}
                            <div className="mb-4">
                                <h6 className="text-[#75757A] text-sm font-normal">Would you like to use the Order page?</h6>
                                <div className="col-span-8 mt-2 ml-1">
                                    <div className="flex flex-wrap items-center gap-5">
                                        <label className="text-typo cursor-pointer mb-0 text-sm font-medium flex items-center gap-1.5 h-8 !rounded-large">
                                            <input
                                                className="h-4 w-4 checked:!ring-primary checked:ring-0 ring-offset-1 cursor-pointer shrink-0 rounded-full border-[1.6px] border-[#3C3E4966] !text-primary   disabled !disbaled:bg-gray-400"
                                                type="radio"
                                                value="yes"
                                                disabled={ButtonSaved}
                                                checked={formState.doYouWantBookingPage === true}
                                                onChange={() => handleChange('doYouWantBookingPage', true)}
                                                name="doYouWantBookingPage"
                                            />
                                            Yes
                                        </label>
                                        <label className="text-typo cursor-pointer mb-0 text-sm font-medium flex items-center gap-1.5 h-8 !rounded-large">
                                            <input
                                                className="h-4 w-4 disabled checked:!ring-primary checked:ring-0 ring-offset-1 cursor-pointer shrink-0 rounded-full border-[1.6px] border-[#3C3E4966] !text-primary  "
                                                type="radio"
                                                value="no"
                                                disabled={ButtonSaved}
                                                checked={formState.doYouWantBookingPage === false}
                                                onChange={() => handleChange('doYouWantBookingPage', false)}
                                                name="doYouWantBookingPage"
                                            />
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>



                            {/* Same Product Detail Page */}
                            {formState.doYouWantBookingPage && (
                                <div className="mb-4">
                                    <h6 className="text-[#75757A] text-sm font-normal">Would you like to show the same product detail page?</h6>
                                    <div className="col-span-8 mt-2 ml-1">
                                        <div className="flex flex-wrap items-center gap-5">
                                            <label className="text-typo cursor-pointer mb-0 text-sm font-medium flex items-center gap-1.5 h-8 !rounded-large">
                                                <input
                                                    className="h-4 disabled w-4 checked:!ring-primary checked:ring-0 ring-offset-1 cursor-pointer shrink-0 rounded-full border-[1.6px] border-[#3C3E4966] !text-primary  "
                                                    type="radio"
                                                    value="yes"
                                                    disabled={ButtonSaved}
                                                    checked={formState.doYouWantToShowProductDetailPage === true}
                                                    onChange={() => handleChange('doYouWantToShowProductDetailPage', true)}
                                                    name="doYouWantToShowProductDetailPage"
                                                />
                                                Yes
                                            </label>
                                            <label className="text-typo cursor-pointer mb-0 text-sm font-medium flex items-center gap-1.5 h-8 !rounded-large">
                                                <input
                                                    className="h-4 disabled w-4 checked:!ring-primary checked:ring-0 ring-offset-1 cursor-pointer shrink-0 rounded-full border-[1.6px] border-[#3C3E4966] !text-primary  "
                                                    type="radio"
                                                    value="no"
                                                    disabled={ButtonSaved}
                                                    checked={formState.doYouWantToShowProductDetailPage === false}
                                                    onChange={() => handleChange('doYouWantToShowProductDetailPage', false)}
                                                    name="doYouWantToShowProductDetailPage"
                                                />
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Product Selection */}
                            {formState.doYouWantBookingPage && (
                                <div className="mb-4">
                                    <label className="block text-gray-700">Select products for the Order page:</label>
                                    <div className="mt-2">

                                        <ProductTable AllProducts={AllProducts} formState={formState} toggleProductSelection={toggleProductSelection} ButtonSaved={ButtonSaved} />
                                    </div>
                                </div>
                            )}
                            <div className="flex justify-end">
                                {/* {!ButtonSaved && <>
                                    <button
                                        type="button"
                                        onClick={e => { setButtonSaved(true); GetPageSettings() }}
                                        className="w-20 text-typo text-sm font-normal flex items-center justify-center  bg-[#fff] rounded-large h-9 shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed mr-2"
                                    >
                                        Cancel
                                    </button>
                                </>} */}
                                {!ButtonSaved && (
                                    <button
                                        disabled={!urlAllow("editbooking-page-settings")}
                                        type="submit"
                                        className={`!px-4 text-sm text-right font-normal text-primary   hover:text-white hover:!no-underline h-9 flex items-center justify-center gap-2 border border-primary rounded-extra_large shadow-btn hover:bg-primary transition-all focus:ring-2 ring-[#EDEBFC] disabled:!cursor-not-allowed`}>
                                        Save
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
 
function PaymentSetting({ formState, GetPageSettings, handlePaymentChange,formSubmitted, setButtonSaved, ButtonSaved, PaymentHandleSubmit }) {
    const cardList = [
        'VISA',
        'MASTERCARD',
        'JCB',
        'AMEX',
        'DISCOVER',
        'DINERS'
    ];
    const user = useSelector((state) => state.user)

    const urlAllow = (url) => {
        if (user?.subRole?.id == environment.SubRolePartner) {
            return true
        }
        let permissions = user?.subRole && user?.subRole?.permissions && user?.subRole?.permissions[0];
        let arr = url?.split(",");
        let value = false;
        arr.map((itm) => {
            if (permissions?.[itm]) value = true;
        });
        if (user?.subRole?.id || user?.subRole?._id) {
            return value;
        } else {
            return true;
        }
    };


    return (
        <div>
            <form className="shadow-box border !border-grey rounded-large container p-5 bg-white" onSubmit={PaymentHandleSubmit}>
                {/* Payment Settings Form */}
                <div className="mb-4">
                    <h6 className="text-[#75757A] text-sm font-normal mb-2">Payment Gateway</h6>
                    <SelectDropdown
                        theme={'search'}
                        intialValue={formState?.paymentGateway}
                        disabled={ButtonSaved}
                        options={[{ id: 'stripe', name: 'Stripe' }]}
                        placeholder="Select Payment Gateway"
                        className="disabled"
                        result={e => handlePaymentChange("paymentGateway", e.value)}
                    />
                    {formSubmitted && !formState?.paymentGateway ? <p className="text-red-400 ml-2">Payment Gateway is required</p> : null}
                </div>
                <div className="mb-4">
                    <h6 className="text-[#75757A] text-sm font-normal mb-2">Cards Accepted</h6>
                    <MultiSelectDropdown
                        theme={'search'}
                        intialValue={formState?.cardsAccept}
                        disabled={ButtonSaved}
                        className="disabled"
                        options={cardList.map(item => ({ id: item, name: item }))}
                        placeholder="Select Card"
                        result={e => handlePaymentChange("cardsAccept", e.value)}
                    />
                    {formSubmitted && !formState?.cardsAccept?.length ? <p className="text-red-400 ml-2">Card is required</p> : null}
                </div>
                <div className="mb-4">
                    <h6 className="text-[#75757A] text-sm font-normal mb-2">Credit Card Surcharge</h6>
                    <SelectDropdown
                        theme={'search'}
                        intialValue={formState?.creditCardSurcharge}
                        disabled={ButtonSaved}
                        className="disabled"
                        options={[{ id: 'yes', name: 'Yes' }, { id: 'no', name: 'No' }]}
                        placeholder="Select Yes/No"
                        result={e => handlePaymentChange("creditCardSurcharge", e.value)}
                    />
                    {!ButtonSaved && !formState?.creditCardSurcharge ? <p className="text-red-400 ml-2" >This is required</p> : null}
                </div>
                {formState?.creditCardSurcharge == 'yes' && <div className="mb-4">
                    <h6 className="text-[#75757A] text-sm font-normal">Surcharge Amount</h6>
                    <input
                        type="number"
                        disabled={ButtonSaved}
                        value={formState?.surCharge}
                        min={0}
                        onKeyDown={e => {
                            if (e.keyCode == 189 || e.key == '-' || e.code == 'Minus') {
                                e.preventDefault()
                            }
                        }}
                        onChange={(e) => !isNaN(e.target.value) && e.target.value >= 0 && handlePaymentChange("surCharge", e.target.value)}
                        className="border rounded w-full p-2 mt-2 disabled"
                    />
                    {!ButtonSaved && formState?.creditCardSurcharge == 'yes' && !formState?.surCharge ? <p className="text-red-400 ml-2" >Surcharge is required</p> : null}
                </div>}
                <div className="mb-4">
                    <h6 className="text-[#75757A] text-sm font-normal">Stripe Key</h6>
                    <input
                        type="text"
                        disabled={ButtonSaved}
                        value={formState?.stripeKey}
                        onChange={(e) => handlePaymentChange("stripeKey", e.target.value)}
                        className="border rounded w-full p-2 mt-2 disabled"
                    />
                    {formSubmitted && !formState?.stripeKey ? <p className="text-red-400 ml-2" >Stripe Key is required</p> : null}
                </div>
                <div className="flex justify-end">
                    {/* {!ButtonSaved && <>
                        <button
                            type="button"
                            onClick={e => { setButtonSaved(true); GetPageSettings() }}
                            className="w-20 text-typo text-sm font-normal flex items-center justify-center  bg-[#fff] rounded-large h-9 shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed mr-2"
                        >
                            Cancel
                        </button>
                    </>} */}
                    {!ButtonSaved && (
                        <button
                            disabled={!urlAllow("editbooking-page-settings")}
                            type="submit"
                            className={`!px-4 text-sm text-right font-normal text-primary   hover:text-white hover:!no-underline h-9 flex items-center justify-center gap-2 border border-primary rounded-extra_large shadow-btn hover:bg-primary transition-all focus:ring-2 ring-[#EDEBFC] disabled:!cursor-not-allowed`}>
                            Save
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
}

export default function Settings() {
    const user = useSelector(state => state.user);
    const [formState, setFormState] = useState({
        doYouWantBookingPage: false,
        doYouWantToShowProductDetailPage: false,
        products: [],
    });

    const [PaymentFormSettings, setPaymentFormSettings] = useState({
        paymentGateway: "",
        cardsAccept: [],
        creditCardSurcharge: "",
        surCharge: '',
        stripeKey: ""
    })
    const [AllProducts, setAllProducts] = useState([]);
    const [ButtonSaved, setButtonSaved] = useState(true);
    const [activeTab, setActiveTab] = useState("booking");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const GetPageSettings = () => {
        loader(true);
        ApiClient.get(`api/booking/page/settings/detail`).then(res => {
            if (res?.success) {
                setFormState({ ...res?.data, products: res?.data?.products?.map((item) => item?._id || item?.id) });
            }
            loader(false);
        });


        ApiClient.get(`api/payment/gateway/detail`).then(res => {
            if (res?.success) {
                setPaymentFormSettings(res?.data);
            }
            loader(false);
        });
    };

    const GetProducts = () => {
        ApiClient.get(`api/v2/products`, { addedBy: user?._id || user?.id, third_party_delete: false }).then(res => {
            if (res.success) {
                setAllProducts(res?.data);
            }
        });
    };

    useEffect(() => {
        GetPageSettings();
        GetProducts();
    }, []);

    const toggleProductSelection = (productId, checked = false) => {
        if (productId === 'all') {
            const ProductIds = AllProducts.map((item) => item?._id || item?.id);
            setFormState(prev => ({ ...prev, products: checked ? ProductIds : [] }));
        } else {
            setFormState(prev => ({
                ...prev,
                products: prev.products.includes(productId)
                    ? prev.products.filter(id => id !== productId)
                    : [...prev.products, productId]
            }));
        }
    };

    const handleChange = (key, value) => setFormState(prev => ({ ...prev, [key]: value }));

    const handlePaymentChange = (key, value) => setPaymentFormSettings(prev => ({ ...prev, [key]: value }));

    const handleSubmit = (e) => {
        e?.preventDefault();
        loader(true);
        ApiClient.put(`api/booking/page/settings`, formState).then(res => {
            if (res.success) {
                swal({
                    text: "Details saved Successfully",
                    icon: "success",
                    className: 'smallmodal',
                })
                setButtonSaved(true);
            }
            loader(false);
        });
    };


    const PaymentHandleSubmit = (e) => {
        e.preventDefault()
        setFormSubmitted(true);
        if (!PaymentFormSettings.paymentGateway || !PaymentFormSettings?.stripeKey || !PaymentFormSettings.cardsAccept?.length || !PaymentFormSettings.creditCardSurcharge) {
            return
        }
        if (!PaymentFormSettings?.surCharge && PaymentFormSettings.creditCardSurcharge == 'yes') {
            return
        }
        loader(true);
        ApiClient.put(`api/payment/gateway`, PaymentFormSettings).then(res => {
            if (res.success) {
                swal({
                    text: "Details saved Successfully",
                    icon: "success",
                    className: 'smallmodal',
                })
                GetPageSettings()
                setButtonSaved(true);
            }
            loader(false);
        })


    }
    return (
        <Layout>
            <div className="max-w-[1020px]">
                <div className="p-2 flex items-center justify-between">
                    <div className="flex flex-col gap-1">
                        <h4 className="text-2xl font-semibold text-[#111827]">Settings</h4>
                        <p className="text-sm font-normal text-[#75757A]">Configure your booking and payment settings</p>
                    </div>
                    {/* {ButtonSaved && (
                        <button onClick={() => setButtonSaved(!ButtonSaved)} className="border border-primary hover:opacity-70 rounded-large bg-[#48119614] w-10 h-10 text-primary   flex items-center justify-center text-xl">
                            <FiEdit3 />
                        </button>
                    )} */}
                </div>
                <div className="flex space-x-4 mt-4">
                    <button className={`py-2 px-4 ${activeTab === "booking" ? "border-b-2 border-primary" : ""}`} onClick={() => { setActiveTab("booking"); GetPageSettings() }}>Booking Page Settings</button>
                    <button className={`py-2 px-4 ${activeTab === "payment" ? "border-b-2 border-primary" : ""}`} onClick={() => { setActiveTab("payment"); GetPageSettings() }}>Payment Settings</button>
                </div>
                {activeTab === "booking" ? (
                    <BookingSetting {...{ formState, handleChange, toggleProductSelection, ButtonSaved: false, setButtonSaved, handleSubmit, GetPageSettings, AllProducts }} />
                ) : (
                    <PaymentSetting {...{ formState: PaymentFormSettings, setButtonSaved, GetPageSettings,formSubmitted, PaymentHandleSubmit, handlePaymentChange, ButtonSaved: false }} />
                )}
            </div>
        </Layout>
    );
}
