import { useRef, useState, useEffect } from 'react';

const useHorizontalScroll = () => {
  const containerRef = useRef(null);
  const [showArrows, setShowArrows] = useState(false);
  const [disableArrows, setDisableArrows] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current) {
        const hasOverflow =
          containerRef.current.scrollWidth > containerRef.current.clientWidth;
        setShowArrows(hasOverflow);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 1000, 
        behavior: 'smooth',
      });
      setDisableArrows(true)
    }
  };

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -1000, 
        behavior: 'smooth',
      });
      setDisableArrows(false)
    }
  };

  return {
    containerRef,
    scrollRight,
    scrollLeft,
    showArrows,
    disableArrows,
  };
};

export default useHorizontalScroll;
