const Data = {
    'dashboard': ['/dashboard'],
    'dynamic-pricing': ['/dynamicprice-list'],
    'price-management': ['/dynamicprice-list'],
    'affiliates': ['/affiliate', '/current-affiliate', '/request', '/general/banners', '/products/banners', '/default-commission', '/affiliateInbox', '/transactions', '/report/sales', '/report/affiliates', '/report/customers'],
    'social-media-marketing': ['/social/media'],
    "users": ["/users"],
    "booking-page-settings": ["/booking/setting"],
    "data-connection": ["/pos"],
    "email-templates": ["/email/templates"],
    "email-list": ["/email/list"],
    "partners": ["/partner"],
    "manage-google-tags": ["/google/tags"],
    "instagram-analysis": ["/marketing/instagram-analysis"],
    "facebook-ads": ["/marketing/facebook-ads"],
    "product-data": ["/product-data"],
    "product-insights": ["/product-dashboard"],
    "refundcancel-data": ['/sales/refunds'],
    "historical-data": ["/products/retrivable"],
    "customer-data": ['/customer-data'],
    "customer-insights": ['/customers'],
    "sales-data": ['/sales-data'],
    "sales-insights": ['/sales'],
    "waiver-database": ['/waidatabase'],
    "waiver-settings": ['/wavier/settings'],
    "waiver-templates": ['/waiver/list'],
    "reviews-settings": ['/reviews/settings'],
    "review-management": ['/reviews'],
    "review-insights": ['/review-dashboard'],
    "widget-settings": ['/omni-channel-setting/widget'],
    "whatsapp": ['/whatsapp/template'],
    "chat": ['/omni-channels/live-chat'],
    "chatbot": ['/chatbot'],
    "google-ads": ["/marketing/google-ads"],
    "google-analytics-summary": ["/marketing/google-analytics"],
    "forecasting": ['/forecasting'],
    "marketing-insights": ["/marketing/insights"],
    "profitability-data": ['/accounting/transactions'],
    "profit-analysis-date": ['/accounting/profit/date'],
    "profit-analysis-month": ['/accounting/profit/month'],
    "insights": ['/accounting/dashboard'],
    "contract-templates": ['/crm'],
    "invoice-templates": ['/invoice/template'],
    "list-of-invoices": ['/reseller-invoices'],
    "list-of-resellers": ['/reseller-data'],
    "resellers-insights": ['/reseller-dashboard'],
    "costing": ['/costing/template'],
    "budget": ['/budget/template'],
    "settings": ['/content/settings'],
    "update-logs": ['/content/updates'],
    "cancellation-policies": ['/cancel/policy'],
    "historical-data": ['/sales/retrievable'],
    "deleted-products": ['/delete/product-data'],
    "smtp-connection": ['/smtp/connection']
};





const ExportSlugs = {
    'visitor-logs': 'export-visitor-logs',
    'reviews': 'export-reviews',
    'wavier-template': 'export-wavier-template',
    'sales-data': 'export-sales-data',
    'customer-data': 'export-customer-data',
    'refund-data': 'export-refund-data',
    'product-data': 'export-product-data',
    'delete-product-data': 'export-delete-product-data',
    'reseller-data': 'export-reseller-data',
    'profitability-data': 'export-profitability-data',
    'contract-templates': 'export-contract-templates'
}




const UrlValidator = (url, PlanData) => {
    const FeatureListing = {}
    const FeaturesList = Object.keys(Data)?.map((itm) => {
        Data[itm]?.map((itmo) => {
            FeatureListing[itmo] = itm
        })
    });
    let ModuleName = FeatureListing[url];
    if (ModuleName) {
        if (PlanData?.includes(ModuleName)) {
            return true
        } else {
            false
        }
    } else {
        return true
    }
}

const SubscriptionModel = { UrlValidator, Data, ExportSlugs };
export default SubscriptionModel