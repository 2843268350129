import datepipeModel from "./datepipemodel"

const list = [
  { id: 'publicHoliday', name: 'Public Holiday', icon: 'public', color: 'publicHColor', class: "bgPublicHColor" },
  { id: 'schoolHoliday', name: 'School Holiday', icon: 'school', color: 'schoolColor', class: "bgSchoolColor" },
  { id: 'events', name: 'Event', icon: 'event', color: 'eventsColorBg', class: "bgEventColor" },
  { id: 'earlyBirdCustomDates', name: 'Early Bird Pricing Custom Dates', icon: 'today', color: 'customDatesColorBg', class: "bgCustomDates" },
  { id: 'earlybirdtimeframes', name: 'Early Bird Pricing Time Frames', icon: 'timelapse', color: 'mainColorBg', class: "bgMainColor" },
  { id: 'earlybirdinventory', name: 'Early Bird Pricing Inventory', icon: 'inventory', color: 'earlyInventoryColorBg', class: "bgEarlyIColor" },
  { id: 'lastminutefixeddates', name: 'Last Minute Pricing Fixed Dates', icon: 'fixed', color: 'lastFixedBg', class: "bgLastFixed" },
  { id: 'lastminuteinventory', name: 'Last Minute Pricing Inventory Discounting', icon: 'inventory', color: 'lastInventoryBg', class: "bgLastIColor" },
]

const slots = [
  { id: '9T10', time: '09:00 to 10:00', start: '09:00:00', end: '10:00:00' },
  { id: '10T11', time: '10:00 to 11:00', start: '10:00:00', end: '11:00:00' },
  { id: '14T15', time: '14:00 to 15:00', start: '14:00:00', end: '15:00:00' },
  { id: '15T16', time: '15:00 to 16:00', start: '15:00:00', end: '16:00:00' },
]

const priceTypesList = [
  { label: 'Adult', amountOrPercent: '', discOrPre: '', number: '' },
  { label: 'Child', amountOrPercent: '', discOrPre: '', number: '' },
  { label: 'Family', amountOrPercent: '', discOrPre: '', number: '' },
  { label: 'Student', amountOrPercent: '', discOrPre: '', number: '' }
]

const onOfDays = [
  { id: '30', name: '30 Days' },
  { id: '60', name: '60 Days' },
  { id: '90', name: '90 Days' }
]

const findSlot = (id) => {
  let ext = slots.find(itm => itm.id == id)
  return ext
}

const find = (key) => {
  let ext = list.find(itm => itm.id == key)
  return ext
}

const name = (key) => {
  let ext = list.find(itm => itm.id == key)
  return ext ? ext.name : key
}

const color = (key) => {
  let ext = list.find(itm => itm.id == key)
  return ext ? ext.color : 'publicHColor'
}

const getDynamicPrice = (date, price, dynamicPrice, type = 'price', spaces = 0) => {

  let value = 0
  let arr = []
  dynamicPrice.map(itm => {
    let p = 0
    if (itm.amountOrPercent == "amount") {
      if (itm.discOrPre == 'Premium') {
        p = price + itm.number
      } else {
        p = price > itm.number ? price - itm.number : price
      }
    } else {
      if (price) {
        let peramt = price * itm.number / 100
        if (itm.discOrPre == 'Premium') {
          p = price + peramt
        } else {
          p = price - peramt
        }
      }
    }

    const getPriceType = (l) => {
      let value = price
      let ext = itm?.priceTypes?.find(pitm => pitm.label == l)
      if (ext) {
        let p = Number(ext?.price)

        if (itm.type == 'lastminuteinventory' || itm.type == 'earlybirdinventory') {
          itm?.applyEarlyBirdPricing.map(citm => {
            if (citm.percentOrAmount && citm.percentOrAmountValue) {
              let conditon = false
              if (citm.symbol == '>') {
                if (spaces > Number(citm.symbolPercentage || 0)) conditon = true
              } else if (citm.symbol == '<') {
                if (spaces < Number(citm.symbolPercentage || 0)) conditon = true
              } else {
                conditon = true
              }

              if (conditon) {
                if (citm.percentOrAmount == "amount") {
                  if (citm.discOrPre == 'Premium') {
                    p = p + Number(citm.percentOrAmountValue || 0)
                  } else {
                    p = p > Number(citm.percentOrAmountValue || 0) ? p - Number(citm.percentOrAmountValue || 0) : p
                  }
                } else {
                  if (p) {
                    let peramt = p * Number(citm.percentOrAmountValue || 0) / 100

                    if (citm.discOrPre == 'Premium') {
                      p = p + peramt
                    } else {
                      p = p - peramt
                    }
                  }
                }
              }
            }
          })
        } else {


          if (itm.amountOrPercent == "amount") {
            if (itm.discOrPre == 'Premium') {
              p = Number(ext?.price) + Number(itm?.number || 0)
            } else {
              p = Number(ext?.price) > Number(itm?.number || 0) ? Number(ext.price) - Number(itm?.number) : Number(ext?.price)
            }
          } else {
            if (ext.price) {

              let peramt = Number(ext.price) * Number(itm?.number || 0) / 100
              if (ext.discOrPre == 'Premium') {
                p = Number(ext.price) + peramt
              } else {
                p = Number(ext.price) - peramt
              }
            }
          }
        }

        value = p

      }


      return Number(value)?.toFixed(2)
    }

    let priceType = itm?.priceTypes?.map(ptitm => {
      return {
        ...ptitm,
        dprice: getPriceType(ptitm.label)
      }
    })

    // if (itm.applyPriceType != 'no') {
    //   priceType = []
    // }



    let cp = p
    if (itm?.applyToDaysTimeSlot == 'no' && itm?.priceTypes?.length) cp = priceType[0]?.dprice

    let timeSlots = []
    if (itm.timeSlots) {
      timeSlots = itm.timeSlots
    }
    let allobj = {}
    if (type == 'array') allobj = itm
    let obj = {
      ...allobj,
      priceType: priceType,
      applyFor: itm.applyFor,
      priceTypes: itm?.type == 'array' ? itm?.priceOptions : itm.priceTypes,
      applyPriceType: itm.applyPriceType,
      date: datepipeModel.datetostring(itm.changesDate),
      applyToDaysTimeSlot: itm.applyToDaysTimeSlot,
      availablespaces: itm.availablespaces,
      applyToDaysTimeSlot: itm.applyToDaysTimeSlot,
      timeSlots: timeSlots,
      changesDate: itm.changesDate,
      changesDateTo: itm.changesDateTo,
      type: itm.type,
      price: Number(Number(cp)?.toFixed(2) || 0)
    }

    let dates = []

    if (itm.type == 'publicHoliday' || itm.type == 'schoolHoliday' || itm.type == 'events') {
      if (itm?.dates) {
        let cdate = itm.changesApply == 'now' ? new Date() : new Date(datepipeModel.datetostring(itm.changesDate))
        itm.dates.map(ditm => {
          const holidaysCondition = () => {
            let value = true
            if (!ditm.isState) {
              if (!itm.applyFor.includes('national')) value = false
            } else {
              if (!itm.applyFor.includes('state')) value = false
            }
            return value
          }
          if (holidaysCondition()) {
            if (new Date(datepipeModel.datetostring(ditm.date)) >= cdate) {
              dates.push({ ...obj, date: datepipeModel.datetostring(ditm.date) })
              if (itm.preOrPost == 'yes') {
                let preArr = datepipeModel.getprepostdays(ditm.date, Number(itm.preDays), 'pre').map(itm => {
                  return { ...obj, date: itm }
                })

                let postArr = datepipeModel.getprepostdays(ditm.date, Number(itm.postDays), 'post').map(itm => {
                  return { ...obj, date: itm }
                })

                preArr = preArr.filter(pitm => new Date(pitm.date) >= cdate)
                postArr = postArr.filter(pitm => new Date(pitm.date) >= cdate)

                dates = [...dates, ...preArr, ...postArr]
              }
            }
          }

        })


      }

    } else if (itm.type == 'earlyBirdCustomDates'
      || itm.type == 'earlybirdtimeframes'
      || itm.type == 'earlybirdinventory'
      || itm.type == 'lastminuteinventory'
      || itm.type == 'lastminutefixeddates' || itm?.type == 'array'
    ) {

      if (itm.type == 'earlybirdtimeframes') {
        let days = Number(itm.onOfDays || 0)
        let today = new Date()
        let end = new Date(today).setDate(today.getDate() + days)
        today = datepipeModel.datetostring(today)
        end = datepipeModel.datetostring(end)

        if (new Date(date) > new Date(end)) {
          dates = [{ ...obj, date: date }]
        }

      } else {
        dates = datepipeModel.getDateStringArray(itm.changesDate, (itm.changesDateTo || itm.changesDate)).map(ditm => {
          return { ...obj, date: ditm }
        })
      }



      if (itm.notApplyCondition && itm.notApply) {
        let conditon = false
        if (itm.notApplyCondition == '>') {
          if (spaces > Number(itm.notApply)) conditon = true
        } else if (itm.notApplyCondition == '<') {
          if (spaces < Number(itm.notApply)) conditon = true
        }

        if (!conditon) dates = []
      }

      if (itm.type == 'lastminuteinventory' || itm.type == 'earlybirdinventory') {
        let carr = []
        itm?.applyEarlyBirdPricing.map(citm => {
          if (citm.percentOrAmount && citm.percentOrAmountValue) {
            let conditon = false
            if (citm.symbol == '>') {
              if (spaces > Number(citm.symbolPercentage || 0)) conditon = true
            } else if (citm.symbol == '<') {
              if (spaces < Number(citm.symbolPercentage || 0)) conditon = true
            } else {
              conditon = true
            }

            if (conditon) {
              let p = 0
              if (citm.percentOrAmount == "amount") {
                if (citm.discOrPre == 'Premium') {
                  p = price + Number(citm.percentOrAmountValue || 0)
                } else {
                  p = price > Number(citm.percentOrAmountValue || 0) ? price - Number(citm.percentOrAmountValue || 0) : price
                }
              } else {
                if (price) {
                  let peramt = price * Number(citm.percentOrAmountValue || 0) / 100

                  if (citm.discOrPre == 'Premium') {
                    p = price + peramt
                  } else {
                    p = price - peramt
                  }
                }
              }
              carr.push({
                ...citm,
                price: Number(p.toFixed(2)),
              })
            }
          }
        })

        let arr = itm?.applyEarlyBirdPricing?.filter(citm => (citm.percentOrAmount && citm.percentOrAmountValue))
        if (!arr.length) {
          carr.push({
            price: price,
          })
        }

        carr = carr.sort((a, b) => {
          return b.price - a.price
        })

        let cp = price
        if (carr.length) cp = carr[0].price
        if (itm?.applyToDaysTimeSlot == 'no' && itm?.priceType?.length) cp = priceType[0].dprice

        dates = dates.map(ditm => {
          return {
            ...ditm,
            price: cp
          }
        })
        // if (carr.length) {
        //   dates = dates.map(ditm => {
        //     return {
        //       ...ditm,
        //       price: carr[0].price
        //     }
        //   })
        // } else {
        //   dates = []
        // }
      }

      if (itm.type == 'lastminuteinventory' || itm.type == 'lastminutefixeddates') {
        let dateArr = datepipeModel.getDateStringArray(itm.lastMinutePricingFromDate, (itm.lastMinutePricingToDate || itm.lastMinutePricingFromDate))
        dates = dates.filter(ditm => dateArr.includes(ditm.date))
      }

      if (itm.blackOutDates) {
        let blockdates = []
        itm.blackOutDates.map(bitm => {
          blockdates = [...blockdates, ...datepipeModel.getDateStringArray(bitm.startDate, (bitm.endDate || bitm.startDate))]
        })
        dates = dates.filter(ditm => !blockdates.includes(ditm.date))
      }

      if (itm?.donotDates?.length) {
        let blockdates = itm?.donotDates.map(ditm => {
          return datepipeModel.datetostring(ditm.date)
        })
        dates = dates.filter(ditm => !blockdates.includes(ditm.date))
      }

      if (itm.applyToDaysTimeSlot == 'no') {
        dates = dates.filter(ditm => itm.daysToApply?.includes(datepipeModel.day(ditm.date)))
        if (itm.availablespaces == 'no') {
          let daysToApply = itm.timeSlots.map(titm => titm.day)
          dates = dates.filter(ditm => daysToApply?.includes(datepipeModel.day(ditm.date)))

        }
      }
    }

    arr = [...arr, ...dates]
  })
  arr = arr.sort(function (a, b) { return b.price - a.price })
  let ext = arr.find(itm => itm.date == date)
  if (ext) {
    value = ext.price
  }

  const getValue = () => {
    if (type == 'array') {
      return arr.filter(itm => itm.date == date)
    } else if (type == 'object') {
      return ext
    } else {
      return ext?.price || 0
    }
  }

  return getValue()
}


const getDynamicPriceArr = (date, price, dynamicPrice, spaces = 0) => {
  let ext = getDynamicPrice(date, price, dynamicPrice, 'array', spaces)
  return ext
}

const avialabilityRes = (res, seats = 0) => {
  // if(res?.platform=='procharter'){
  //   let data=res.data['soap:Envelope']['soap:Body']['GetAvailabilityBasicResponse']['GetAvailabilityBasicResult']['Availabilities']?.['AvailabilityBasic']||[]
  //   if(data.length){
  //     data=data.map(itm=>{
  //       let priceOptions=itm?.RunClassTypes?.RunClassType?.map(itm=>{
  //         let payload={
  //           item:itm,
  //           price:itm.NetRate,
  //           label:itm.Description,
  //           id:itm.ClassTypeID,
  //           seatsUsed:0,
  //           productCode:'',
  //           seatsAvailable:Number(itm.AvailableCount||'0')
  //         }
  //         return payload
  //       })||[]
  //       let payload={
  //         item:itm,
  //         allDay:false,
  //         endTime:itm.DateEnd,
  //         endTimeLocal:`${datepipeModel.datetostring(itm.DateEnd)} ${datepipeModel.timeString(itm.DateEnd)}`,
  //         productCode:'',
  //         priceOptions:priceOptions,
  //         seats:Number(seats||0),
  //         seatsAvailable:Number(itm.AvailablePax),
  //         startTime:itm.DateStart,
  //         startTimeLocal:`${datepipeModel.datetostring(itm.DateStart)} ${datepipeModel.timeString(itm.DateStart)}`,
  //       }
  //       return payload
  //     })
  //     res.data=data
  //   }

  // }

  return res
}

const dynamicPriceModel = { list, find, name, getDynamicPrice, getDynamicPriceArr, slots, findSlot, priceTypesList, color, onOfDays, avialabilityRes }
export default dynamicPriceModel