
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
import Layout from '../../../components/global/layout';
import { HiOutlineArrowDown, HiOutlineSearch } from 'react-icons/hi';
import datepipeModel from '../../../models/datepipemodel';
import pipeModel from '../../../models/pipeModel';
import { MdClear } from 'react-icons/md';
import DateRangePicker from '../../../components/common/DateRangePicker';
export default function EmailBookingList() {
    const user = useSelector(state => state.user)
    const searchState = useSelector((state) => state.search);
    const [filters, setFilter] = useState({ search: '', user_id: user?.supplier_id ? user?.supplier_id?._id : user?.id || user?._id, page: 1, count: 50 })
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [loaging, setLoader] = useState(true)

    const filter = (p = {}) => {
        setFilter({ ...filters, page: 1, ...p })
        getData({ page: 1, ...p })
    }


    const sorting = (key) => {
        let sorder = 'asc'
        if (filters.key == key) {
            if (filters.sorder == 'asc') {
                sorder = 'desc'
            } else {
                sorder = 'asc'
            }
        }


        let sortBy = `${key} ${sorder}`;
        filter({ sortBy, key, sorder })
    }

    useEffect(() => {
        if (user && user.loggedIn) {
            setFilter({ ...filters, search: searchState.data })
            getData({ search: searchState.data, page: 1 })
        }
    }, [searchState])




    const getData = (p = {}, loaderr = true) => {
        if (loaderr) {
            loader(true)
            setLoader(true)
        }
        let filter = { ...filters, ...p, }

        ApiClient.get('api/email/bookings', filter, '', '', { apiCall: true }).then(res => {
            if (res.success) {
                setData(res.data.map(itm => {
                    itm.id = itm._id
                    return itm
                }))
                setTotal(res.total)

            }
            loader(false)
            setLoader(false)
        })
    }




    const pageChange = (e) => {
        setFilter({ ...filters, page: e })
        getData({ page: e })
    }

    const onRangeChange = (e) => {
        let f = {
            startDate: datepipeModel.datetostring(e.startDate),
            endDate: datepipeModel.datetostring(e.endDate),
        };
        filter({ ...f });
    };

    return <>
        <Layout>
            <div className="flex items-center justify-between mb-6">
                <div className="flex flex-col gap-1">
                    <h4 className="text-2xl font-semibold text-[#111827]">Email Orders List</h4>
                    <p className="text-sm font-normal text-[#75757A]">
                        List of Orders which is created by Email
                    </p>
                </div>
            </div>

            <div className="bg-white shadow-box border !border-grey rounded-large w-full max-w-[1280px]">
                <div className="flex items-start justify- gap-3 p-6">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            filter();
                        }}
                        className="flex items-center !gap-2">
                        <div className="relative shadow-box border !border-grey bg-white min-w-[320px] rounded-large h-10 flex items-center">
                            <HiOutlineSearch className="text-[#717275] text-xl shrink-0 absolute left-2 top-2.5 z-10" />
                            <input
                                className="w-full h-full outline-primary absolute inset-0 pl-9 focus:right-2 rounded-large focus:ring-primary p-0 placeholder:text-[#98A2B3] text-typo text-sm placeholder:font-light"
                                value={filters.search}
                                onChange={(e) => {
                                    if (e.target.value == '') {
                                        filter({ search: "" })
                                    }
                                    setFilter({ ...filters, search: e.target.value })
                                }
                                }
                                placeholder="Search"
                            />
                        </div>
                        <button className="border border-primary h-10 shadow-btn px-6 hover:bg-primary hover:text-white text-sm text-primary   transition-all rounded-extra_large">
                            Search
                        </button>

                    </form>
                    <DateRangePicker
                        outerClass="!left-0"
                        placeholder="Order Date"
                        value={{
                            startDate: filters.startDate,
                            endDate: filters.endDate,
                        }}
                        allTime={true}
                        allTimeText="Order Date"
                        onChange={(e) => onRangeChange(e)}
                    />
                    <div>
                        {filters.search || filters?.startDate ? (
                            <button
                                className="bg-white !px-4 text-sm font-normal text-primary   h-10 flex items-center justify-center gap-2 rounded-extra_large shadow-btn hover:bg-primary hover:text-white border border-primary transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed"
                                onClick={(e) => filter({ search: "", startDate: '', endDate: '' })}>
                                <MdClear className="text-xl" />
                                Clear
                            </button>
                        ) : null}
                    </div>
                </div>
                <div className="scrollbar w-full overflow-auto">
                    <table className="w-full">
                        <thead className="border-y border-[#EAECF0]">
                            <tr className="border-y border-[#EAECF0]">
                                <th
                                    scope="col"
                                    className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 cursor-pointer"
                                >
                                    Order Id

                                </th>
                                <th
                                    scope="col"
                                    className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 cursor-pointer"
                                >
                                    Order Date

                                </th>

                                <th
                                    scope="col"
                                    className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 cursor-pointer"
                                >
                                    Product Name

                                </th>

                                <th
                                    scope="col"
                                    className="text-[#82838B] text-center !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3">
                                    Name of Customer
                                </th>


                                <th
                                    scope="col"
                                    className="text-[#82838B] text-center !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3">
                                    Amount
                                </th>

                                <th
                                    scope="col"
                                    className="text-[#82838B] text-center !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3">
                                    Travel Date
                                </th>
                                <th
                                    scope="col"
                                    className="text-[#82838B] text-center !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3">
                                    Reseller Name
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loaging &&
                                data &&
                                data.map((itm, i) => {
                                    return (
                                        <tr className="data_row" key={i}>
                                            <td
                                                className="!text-typo !border-l-0 !border-b-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]"

                                            >
                                                {itm?.orderDetail?.orderNumber}
                                            </td>
                                            <td
                                                className="!text-typo !border-l-0 !border-b-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]"

                                            >
                                                {datepipeModel.date(itm?.orderDetail?.dateCreated?.split('T')[0], user?.companyDateFormat) || '---'}
                                            </td>
                                            <td
                                                className="!text-typo !border-l-0 !border-b-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]"

                                            >
                                                {itm?.orderDetail?.items && itm?.orderDetail?.items[0]?.productName}
                                            </td>

                                            <td className="!text-typo !border-l-0 !border-b-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                                                {itm?.orderDetail?.customer?.name || '---'}
                                            </td>
                                            <td
                                                className="!text-typo !border-l-0 !border-b-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]"

                                            >
                                                {pipeModel.currency(itm?.orderDetail?.totalAmount, '$', user?.companyCurrencyFormat) || '---'}
                                            </td>
                                            <td
                                                className="!text-typo !border-l-0 !border-b-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]"

                                            >
                                                {datepipeModel.date(itm?.orderDetail?.travelDate?.split('T')[0], user?.companyDateFormat) || '---'}
                                            </td>
                                            <td
                                                className="!text-typo !border-l-0 !border-b-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]"

                                            >
                                                {itm?.orderDetail?.resellerName || '---'}
                                            </td>

                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
                {!loaging && total == 0 ? (
                    <div className="py-3 text-center">No Data</div>
                ) : (
                    <></>
                )}

                {!loaging && total > filters.count ? (
                    <div className="p-6">
                        <div className="paginationWrapper">
                            <Pagination
                                currentPage={filters.page}
                                totalSize={total}
                                sizePerPage={filters.count}
                                changeCurrentPage={pageChange}
                            />
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>

            {loaging ? (
                <div className="loaderDiv py-4 text-center">
                    <img
                        src="/assets/img/loader.gif"
                        width="auto"
                        height="auto"
                        className="pageLoader"
                    />
                </div>
            ) : (
                <></>
            )}
        </Layout>
    </>;
};


