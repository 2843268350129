import axios from "axios";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../components/global/layout";
import environment from "../../environment";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import datepipeModel from "../../models/datepipemodel";
import orderStatusModel from "../../models/orderStatus.model";
import Pagination from "react-pagination-js";
import pipeModel from "../../models/pipeModel";
import { HiOutlineArrowDown, HiOutlineSearch } from "react-icons/hi";
import methodModel from "../../methods/methods";
import PaginationTextView from "../../components/common/PaginationTextView";
import DOMPurify from "dompurify";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import useHorizontalScroll from '../../hooks/useHorizontalScroll';
import SelectDropdown from "../../components/common/SelectDropdown";
import { colourStyles } from "../../utils/constants";
import Select from "react-select";
import { RxReset } from "react-icons/rx";
import DateRangePicker from "../../components/common/DateRangePicker";
import { MdClear } from "react-icons/md";

const PromoCodeSalesData = () => {
    const user = useSelector((state) => state.user);
    const [orderslist, setOrderlist] = useState([]);
    const [bookings, setbookings] = useState([]);
    const [total, setTotal] = useState(0);

    const [filters, setFilter] = useState({
        page: 1,
        count: 50,
        sortBy: "dateCreated desc",
        search: "",
        posId: "",
        status: "",
        productCode: "",
        category: [],
        travelStartDate: "",
        travelEndDate: "",
        reseller: "",

    });


    const getOrders = (p = {}, loading = true) => {
        loader(loading);
        let filter = {
            ...filters,
            ...p,
            addedBy: user.supplier_id ? user.supplier_id.id : user._id,
        };
        if (user?.subRole && user?.subRole?.id == environment.SubRolePartner) {
            delete filter.addedBy;
        }

        const response = (res) => {
            if (res.success) {
                let data = res.data.map((itm) => {
                    let totalQuantity = 0;
                    itm?.items?.map((oitm) => {
                        totalQuantity = totalQuantity + oitm.totalQuantity;
                    });
                    return {
                        ...itm,
                        id: itm._id,
                        totalQuantity: totalQuantity,
                    };
                });
                setOrderlist(data);
                setTotal(res.total);
            }
            loader(false);
        };

        ApiClient.getAll({
            url: "api/promo/orders",
            params: filter,
            response: response,
        });
    };


    useEffect(() => {
        getOrders();

    }, []);

    const filter = (p = {}) => {
        let f = { ...filters, ...p };
        localStorage.setItem("salesDataFilter", JSON.stringify(f));
        setFilter({ ...f });
        getOrders(p);
    };





    const pageChange = (e) => {

        setFilter({ ...filters, page: e });
        getOrders({ page: e });
        try {
            window.scrollTo(0, 0)
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE
            document.body.scrollTop = 0; // For Safari
        } catch {
            console.log('error')
        }
    };

    const sortClass = (key) => {
        let cls = "fa-sort";
        if (filters.key == key && filters.sorder == "asc") cls = "fa-sort-up";
        else if (filters.key == key && filters.sorder == "desc")
            cls = "fa-sort-down";
        return "fa " + cls;
    };

    const sorting = (key) => {
        let sorder = "asc";
        if (filters.key == key) {
            if (filters.sorder == "asc") {
                sorder = "desc";
            } else {
                sorder = "asc";
            }
        }

        let sortBy = `${key} ${sorder}`;
        setFilter({ ...filters, sortBy, key, sorder });
        getOrders({ sortBy });
    };



    const phoneNumber = (p) => {
        let value = String(p);
        try {
            if (value) {
                if (!value?.includes("+")) {
                    value = "+" + value;
                }
            }
            return value;
        } catch {
            return null
        }
    };

    const { containerRef, scrollRight, scrollLeft, showArrows, disableArrows } = useHorizontalScroll();
    function formatNumber(value) {
        // Convert the number to a fixed-point notation with 2 decimals
        let formattedValue = value.toExponential(2);

        // Check if it's in scientific notation
        if (formattedValue.includes('e')) {
            // Extract only the coefficient (before "e")
            return formattedValue.split('e')[0];
        }

        return formattedValue; // Return as-is if no "e"
    }

    const [ResellerOptions, setResellerOptions] = useState([]);
    const GetResellerOptions = () => {
        ApiClient.get(`api/v2/reseller/all?sortBy=lifeTimeSale%20desc&supplier_id=${user?.supplier_id ? user?.supplier_id?.id : user?._id}&page=1&count=200`).then((_res) => {
            if (_res?.success) {
                setResellerOptions(_res?.data?.map((_reseller) => {
                    return ({ name: _reseller.name, id: _reseller?.id || _reseller?._id })
                }))
            }
        })
    }
    useEffect(() => {
        GetResellerOptions()
    }, []);


    const PromodeUpdater = (reseller = '', promoCode = '', index) => {
        loader(true)
        const previousData = orderslist;
        previousData[index]['reseller'] = reseller;
        setOrderlist([...previousData])
        ApiClient.put(`api/promo/orders/reseller`, { promoCode: promoCode, reseller: reseller }).then((_res) => {
            if (_res?.success) {
                getOrders({}, false)
            }
        })
    }
    const [resellers, setResellers] = useState([]);

    const getResellers = () => {
        const filter = { partner: user?.id || user?._id };
        if (user?.subRole && user?.subRole?.id == environment.SubRolePartner) {
        } else {
            delete filter.partner;
        }
        ApiClient.get("api/filter/orders/reseller-list", filter).then((res) => {
            if (res.success) {
                setResellers(
                    res.data.map((itm) => {
                        return { value: itm.id, label: itm.name };
                    })
                );
            }
        });
    };
    const resellerVal = () => {
        let ext =
            resellers && resellers.find((item) => item.value == filters.reseller);
        return ext ? ext : "";
    };
    const PromoCodeVal = () => {
        let ext =
            PromoCodeLists && PromoCodeLists.find((item) => item.value == filters.promoCode);
        return ext ? ext : "";
    }

    const [PromoCodeLists, setPromoCodeList] = useState([]);
    const PromoCodeList = () => {
        const filter = { partner: user?.id || user?._id, addedBy: user?.supplier_id?._id || user?._id, page: 1, count: 1000 };

        ApiClient.get("api/promocodes/list", filter).then((res) => {
            if (res.success) {
                setPromoCodeList(
                    res.data.map((itm) => {
                        return { value: itm?.promoCode, label: itm?.promoCode };
                    })
                );
            }
        });
    }
    useEffect(() => {
        getResellers()
        PromoCodeList()
    }, []);



    const onRangeChange = (e, type = "order") => {
        let f = {
            startDate: datepipeModel.datetostring(e.startDate),
            endDate: datepipeModel.datetostring(e.endDate),
        };

        filter({ ...f });
    };



    const clearKey = {
        search: "",
        reseller: "",
        startDate: "",
        endDate: "",
        promoCode: ""
    };

    const isClear = () => {
        let value = false;

        Object.keys(clearKey).map((itm) => {
            if (filters[itm]) value = true;
        });
        return value;
    };

    const clear = () => {
        let p = clearKey;
        Object.keys(clearKey).map((itm) => {
            p[itm] = "";
        });
        let f = { ...filters, ...p, page: 1 };
        localStorage.setItem("PromoDataFilter", JSON.stringify(f));
        setFilter({ ...f });
        getOrders({ ...p, page: 1 });
    };
    return (
        <Layout>
            <div>
                <div className="flex items-center justify-between mb-6">
                    <div className="flex flex-col gap-1">
                        <h4 className="text-2xl font-semibold text-[#111827]">
                            Sales Promo Code Data
                        </h4>
                        <p className="text-sm font-normal text-[#75757A]">
                            Detailed Sales Data by Booking/Order ID
                        </p>
                    </div>

                    <div className="flex gap-3">

                    </div>
                </div>

                <div className="shadow-box border !border-grey w-full bg-white rounded-large">
                    <div className="flex  2xl:flex-wrap items-start gap-3 p-6 max-[1535.98px]:flex-wrap">
                        <div className="flex items-center gap-2">
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    filter();
                                }}
                                className="relative shadow-box border !border-grey bg-white min-w-[320px] rounded-extra_large h-10 flex items-center gap-2 overflow-hidden px-2">
                                <HiOutlineSearch className="text-[#717275] text-xl shrink-0" />
                                <input
                                    className="w-full h-full outline-none ring-0 p-0 placeholder:text-[#98A2B3] text-typo text-sm placeholder:font-light"
                                    value={filters.search}
                                    onChange={(e) => {
                                        if (e.target.value == '') {
                                            filter({ search: "" })
                                        }
                                        setFilter({ ...filters, search: e.target.value })
                                    }
                                    }
                                    placeholder="Search for Order or Customer"
                                />
                            </form>
                            <button
                                className="border border-primary h-10 shadow-btn px-6 hover:bg-primary hover:text-white text-sm text-primary   transition-all rounded-extra_large"
                                onClick={(e) => filter()}>
                                Search
                            </button>
                        </div>
                        <Select
                            styles={colourStyles}
                            options={[
                                { label: "All Resellers", value: "" },
                                ...resellers,
                            ]}
                            placeholder="All Resellers"
                            value={resellerVal()}
                            isClearable={true}
                            name="Resellers"
                            onChange={(e) => filter({ reseller: e ? e.value : "" })}
                        />
                        <Select
                            styles={colourStyles}
                            options={[
                                { label: "All PromoCodes", value: "" },
                                ...PromoCodeLists,
                            ]}
                            placeholder="Select PromoCode"
                            value={PromoCodeVal()}
                            isClearable={true}
                            onChange={(e) => filter({ promoCode: e ? e.value : "" })}
                        />



                        <div className="flex !gap-1 items-center relative data_sales_calender">
                            <DateRangePicker
                                outerClass="!left-0"
                                placeholder=" Date"
                                value={{
                                    startDate: filters.startDate,
                                    endDate: filters.endDate,
                                }}
                                allTime={true}
                                allTimeText="Date"
                                onChange={(e) => onRangeChange(e)}
                            />
                            {filters.startDate ? (
                                <>
                                    <button
                                        onClick={(e) => filter({ startDate: "", endDate: "" })}
                                        type="button"
                                        className="bg-white !px-4 text-sm font-normal text-primary   h-10 flex items-center justify-center gap-2 rounded-extra_large shadow-btn hover:bg-primary hover:text-white border border-primary transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                                        <RxReset className="text-xl" />
                                    </button>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>

                        {isClear() ? (
                            <button
                                onClick={(e) => clear()}
                                type="button"
                                className="bg-white !px-4 text-sm font-normal text-primary   h-10 flex items-center justify-center gap-2 rounded-extra_large shadow-btn hover:bg-primary hover:text-white border border-primary transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                                <MdClear className="text-xl" />
                                Clear
                            </button>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="relative">
                        {(showArrows && disableArrows) && (
                            <button
                                className="absolute left-2 top-4 p-2 bg-[#82838B] rounded-full"
                                onClick={scrollLeft}
                            >
                                <IoIosArrowBack size={26} className="text-white" />
                            </button>
                        )}


                        <div className="scrollbar w-full overflow-auto" ref={containerRef}>
                            <table className=" w-full">
                                <thead className="border-y border-[#EAECF0]">
                                    <tr className=" border-y border-[#EAECF0]">
                                        <th
                                            scope="col"
                                            className="cursor-pointer text-[#82838B] !border-l-0 font-normal text-xs 2xl:text-sm !border border-[#EAECF0] px-3.5  text-center bg-[#F7FAFF] !py-3 w-[90px]"
                                            onClick={(e) => sorting("orderNumber")}>
                                            Order Id
                                            <HiOutlineArrowDown className="shrink-0 inline text-sm" />
                                        </th>
                                        <th
                                            scope="col"
                                            className=" cursor-pointer text-[#82838B] border-l-0 font-normal text-xs 2xl:text-sm !border border-[#EAECF0] px-3.5  text-center bg-[#F7FAFF] !py-3 w-[90px]"
                                            onClick={(e) => sorting("dateCreated")}>
                                            Order <br /> Date & Time{" "}
                                            <HiOutlineArrowDown className="shrink-0 inline text-sm" />
                                        </th>
                                        <th
                                            scope="col"
                                            className="cursor-pointer text-[#82838B] font-normal text-xs 2xl:text-sm !border border-[#EAECF0] px-3.5  text-left bg-[#F7FAFF] !py-3 w-[200px]"
                                            onClick={(e) => sorting("customerName")}>
                                            <span className="flex flex-wrap items-center gap-0.5">
                                                Customer  Name{" "}
                                                <HiOutlineArrowDown className="shrink-0 inline text-sm" />
                                            </span>
                                        </th>
                                        <th
                                            scope="col"
                                            className="cursor-pointer text-[#82838B] font-normal text-xs 2xl:text-sm !border border-[#EAECF0] px-3.5  text-left bg-[#F7FAFF] !py-3 w-[200px]"
                                        >
                                            <span className="flex flex-wrap items-center gap-0.5">
                                                Promo Code{" "}
                                            </span>
                                        </th>
                                        <th
                                            scope="col"
                                            className="cursor-pointer text-[#82838B] font-normal text-xs 2xl:text-sm !border border-[#EAECF0] px-3.5  text-left bg-[#F7FAFF] !py-3 w-[200px]"
                                        >
                                            <span className="flex flex-wrap items-center gap-0.5">
                                                Discount{" "}
                                            </span>
                                        </th>
                                        <th
                                            scope="col"
                                            className="cursor-pointer text-[#82838B] font-normal text-xs 2xl:text-sm !border border-[#EAECF0] px-3.5  text-left bg-[#F7FAFF] !py-3 w-[200px]"
                                        >
                                            <span className="flex flex-wrap items-center gap-0.5">
                                                Promo Code Date{" "}
                                            </span>
                                        </th>
                                        {/* <th
                                            scope="col"
                                            className="cursor-pointer text-[#82838B] font-normal text-xs 2xl:text-sm !border border-[#EAECF0] h-10 px-3.5 text-left bg-[#F7FAFF] !py-3 w-[240px]"
                                            onClick={(e) => sorting("customerEmail")}>
                                            Customer Email/Phone
                                            <HiOutlineArrowDown className="shrink-0 inline text-sm" />
                                        </th> */}
                                        <th
                                            scope="col"
                                            className="cursor-pointer text-[#82838B] border-l-0 font-normal text-xs 2xl:text-sm !border border-[#EAECF0] px-3.5  text-left bg-[#F7FAFF] w-[180px] !py-3">
                                            {/* <span className=" whitespace-nowrap">Product Name</span> */}
                                            Product Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="cursor-pointer text-[#82838B] font-normal text-xs 2xl:text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3 w-[120px]">
                                            Pax
                                        </th>
                                        <th
                                            scope="col"
                                            className="cursor-pointer text-[#82838B] font-normal text-xs 2xl:text-sm !border border-[#EAECF0] px-3.5  text-center bg-[#F7FAFF] !py-3 w-[100px]"
                                            onClick={(e) => sorting("totalAmount")}>
                                            <span className="flex flex-wrap items-center justify-center gap-0.5 whitespace-nowrap">
                                                Order <br /> Amount
                                                <HiOutlineArrowDown className="shrink-0 inline text-sm" />
                                            </span>
                                        </th>

                                        <th
                                            scope="col"
                                            className="cursor-pointer text-[#82838B] font-normal text-xs 2xl:text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3 w-[100px]">
                                            Booking <br /> Date & Time
                                        </th>
                                        <th
                                            scope="col"
                                            className="cursor-pointer text-[#82838B] font-normal text-xs 2xl:text-sm !border border-[#EAECF0] px-3.5  text-center bg-[#F7FAFF] !py-3 w-[80px]"
                                            onClick={(e) => sorting("resellerName")}>
                                            <span className="flex flex-wrap  items-center justify-center gap-0.5">
                                                Reseller
                                                <HiOutlineArrowDown className="shrink-0 inline text-sm" />
                                            </span>
                                        </th>
                                        <th
                                            scope="col"
                                            className="cursor-pointer text-[#82838B] font-normal text-xs 2xl:text-sm !border border-[#EAECF0] px-3.5  text-center bg-[#F7FAFF] !py-3 w-[70px]"
                                            onClick={(e) => sorting("commission")}>
                                            Reseller <br /> Comm...
                                            <HiOutlineArrowDown className="shrink-0 inline text-sm" />
                                        </th>
                                        <th
                                            scope="col"
                                            className="cursor-pointer text-[#82838B] font-normal text-xs 2xl:text-sm !border border-[#EAECF0] px-3.5  text-center bg-[#F7FAFF] !py-3 w-[85px]"
                                        >
                                            Payment Type

                                        </th>
                                        <th
                                            scope="col"
                                            className="cursor-pointer text-[#82838B] font-normal text-xs 2xl:text-sm !border border-[#EAECF0] px-3.5  text-center bg-[#F7FAFF] !py-3 w-[85px]">
                                            Order
                                            <br /> Status
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {orderslist &&
                                        orderslist.map((itm, i) => {
                                            return (
                                                <tr key={i} >
                                                    <td

                                                        className="hover:underline hover:opacity-80 !text-primary   !border-l-0 cursor-pointer !px-3.5  text-sm font-normal !py-4 !border text-center border-[#EAECF0]"
                                                    >

                                                        <Link to={`/orders/${itm?.id}`}>
                                                            {itm?.orderNumber}
                                                        </Link>
                                                    </td>
                                                    <td className=" text-typo !px-1.5 text-xs xl:text-sm font-normal !py-4 !border text-center border-[#EAECF0]">
                                                        <span className="whitespace-nowrap text-sm">
                                                            {datepipeModel.date(
                                                                itm?.dateCreated,
                                                                user?.companyDateFormat,
                                                                true,
                                                                itm?.productDetail?.timezone
                                                            )}
                                                        </span>

                                                        <p className="text-[#6B7280] text-sm">
                                                            {datepipeModel.time(
                                                                itm.dateCreated,
                                                                // itm?.productDetail?.timezone,
                                                                null,
                                                                true,
                                                            )}
                                                        </p>
                                                    </td>
                                                    <td className=" text-typo !px-3.5  text-xs xl:text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                                                        <span className="line-clamp-2 text-sm">
                                                            {" "}
                                                            {itm?.platform == "hakatours"
                                                                ? itm?.travellers
                                                                    ?.map((item) => item?.first_name)
                                                                    .toString()
                                                                : itm?.customer?.name || itm?.customerName}
                                                        </span>

                                                    </td>

                                                    <td className=" text-typo !px-3.5  text-xs xl:text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                                                        <span className="line-clamp-2 text-sm">
                                                            {itm?.promoCode}
                                                        </span>
                                                    </td>
                                                    <td className=" text-typo !px-3.5  text-xs xl:text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                                                        <span className="line-clamp-2 text-sm">
                                                            {pipeModel.currency(itm?.promoAmount, '$', user?.companyCurrencyFormat)}
                                                        </span>
                                                    </td>
                                                    <td className=" text-typo !px-3.5  text-xs xl:text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                                                        <span className="line-clamp-2 text-sm">
                                                            {datepipeModel.date(itm?.promoDate, user?.companyDateFormat)}
                                                        </span>
                                                    </td>
                                                    {/* <td className=" text-typo whitespace-nowrap !pl-3 !pr-2 text-xs xl:text-sm font-normal !py-4 border text-left border-[#EAECF0]">
                                                        <div className="break-all  whitespace-normal  ">

                                                            {itm?.customer?.email}
                                                        </div>
                                                        <div className="flex  break-all w-72 whitespace-nowrap !mt-2">
                                                            {itm?.customer?.phone && (
                                                                <p className="text-[#6B7280] !text-xs">
                                                                    {phoneNumber(itm?.customer?.phone)}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </td> */}
                                                    <td className=" text-typo !px-3.5  text-xs xl:text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                                                        <span className="line-clamp-2 text-sm">
                                                            {/* {itm?.items[0]?.productName} */}
                                                            {itm?.productName}
                                                        </span>
                                                    </td>
                                                    <td className="text-typo !px-0 text-xs xl:text-sm font-normal !py-4 !border text-center border-[#EAECF0]">
                                                        {itm?.totalPax}
                                                    </td>
                                                    <td className="text-typo !px-3.5  text-xs xl:text-sm font-normal !py-4 border text-right border-[#EAECF0]">
                                                        {pipeModel.currency(
                                                            Number(itm?.totalAmount),
                                                            "",
                                                            user.companyCurrencyFormat
                                                        )}
                                                    </td>


                                                    <td className=" text-typo !px-3.5  text-sm xl:text-sm font-normal !py-4 !border text-center border-[#EAECF0]">
                                                        <span className="whitespace-nowrap">
                                                            {datepipeModel.date(
                                                                itm?.travelDate,
                                                                user.companyDateFormat,
                                                                true,
                                                                // itm?.timezone
                                                            )}
                                                        </span>
                                                        <p className="text-[#6B7280] text-sm">
                                                            {datepipeModel.time(
                                                                itm?.travelDate,
                                                                itm?.timezone,
                                                                true
                                                            )}
                                                        </p>
                                                    </td>
                                                    <td className=" text-typo !px-3.5 w-[100px]  text-xs xl:text-sm font-normal !py-4 !border text-center  border-[#EAECF0]">
                                                        <SelectDropdown
                                                            intialValue={itm?.reseller?._id || itm?.reseller?.id || itm?.reseller}
                                                            options={ResellerOptions}
                                                            placeholder="Select Reseller"
                                                            className="w-[100px]"
                                                            result={e => {
                                                                PromodeUpdater(e?.value, itm?.promoCode, i)
                                                            }}
                                                        />
                                                    </td>

                                                    <td className="text-typo !px-3.5  text-sm xl:text-sm font-normal !py-4 !border text-right border-[#EAECF0]">
                                                        {pipeModel.currency(
                                                            Number(itm?.commission),
                                                            "",
                                                            user.companyCurrencyFormat
                                                        )}
                                                    </td>
                                                    <td className=" text-typo !px-3.5  text-xs xl:text-sm font-normal !py-4 !border text-left border-[#EAECF0] ">
                                                        <div className="w-[100px]">
                                                            <span className=" text-sm" style={{ wordBreak: 'break-all' }}>
                                                                {itm?.payments?.map((item) => item?.type).join(",")}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className=" text-typo !px-4  text-xs font-normal !py-4 !border text-center border-[#EAECF0]">
                                                        {itm.status === "CANCELLED" && (
                                                            <div className="bg-[#FDEBED] text-xs !px-1 h-[30px] flex cursor-pointer items-center justify-center border border-[#ECA59E] text-[#D23D28] !rounded">
                                                                {orderStatusModel.name(itm?.status)}
                                                            </div>
                                                        )}
                                                        {itm.status === "PENDING_SUPPLIER" && (
                                                            <div className="bg-[#FEF2DB] text-xs !px-1 h-[30px] flex cursor-pointer items-center justify-center border border-[#EAC785] text-[#CB8704] !rounded">
                                                                {orderStatusModel.name(itm?.status)}
                                                            </div>
                                                        )}
                                                        {itm.status === "CONFIRMED" && (
                                                            <div className="bg-[#ECF4EF] text-xs !px-1 h-[30px] flex cursor-pointer items-center justify-center border border-[#94D5AE] text-[#11A74D] !rounded">
                                                                {orderStatusModel.name(itm?.status)}
                                                            </div>
                                                        )}
                                                        {itm.status === "REBOOKED" && (
                                                            <div className="bg-[#FDEBED] text-xs !px-1 h-[30px] flex cursor-pointer items-center justify-center border border-[#ECA59E] text-[#D23D28] !rounded">
                                                                {orderStatusModel.name(itm?.status)}
                                                            </div>
                                                        )}
                                                    </td>

                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                        {(showArrows && disableArrows === false) && (
                            <button
                                className="absolute right-2 top-4 p-2 bg-[#82838B] rounded-full flex justify-center items-center"
                                onClick={scrollRight}
                            >
                                <IoIosArrowForward size={26} className="text-white" />
                            </button>
                        )}
                    </div>

                    {!total ? <div className="py-3 text-center">No Data</div> : <></>}
                    {total > filters?.count && (
                        <div className="paginationWrapper flex items-center justify-between p-6">
                            <div className="table_rowings ">
                                <p className="max-w-96 text-sm text-gray-600">
                                    {" "}
                                    {PaginationTextView(filters.page, filters.count, total)}
                                </p>
                            </div>
                            <Pagination
                                currentPage={filters.page}
                                totalSize={total}
                                sizePerPage={filters.count}
                                changeCurrentPage={pageChange}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default PromoCodeSalesData;
