/*********** Reduceres defined here *********/

import { persistCombineReducers } from "redux-persist";
import storage from "redux-persist/es/storage"; // default: localStorage if web, AsyncStorage if react-native

// reducers
import user from "./modules/user";
import rules from "./modules/rule";
import loader from "./modules/loader";
import logo from "./modules/logo";
import Subscription from "./modules/subscription";
import sidebar from './modules/sidebar'
import search from "./modules/search";
import chatbot from "./modules/chatbot";
import { createBrowserHistory } from "history";

/*********** History function **************/
export const history = createBrowserHistory();

const userPersistConfig = {
  key: "admin-app",
  storage: storage,
  blacklist: ["router", "loader"],
};

export default persistCombineReducers(userPersistConfig, {
  router: history,
  loader,
  logo,
  user,
  rules,
  search,
  sidebar,
  chatbot,
  Subscription
});
